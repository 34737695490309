const _jsxFileName = "C:\\Users\\phung\\Downloads\\ad4gd-open-dash\\src\\js\\components\\LakeOverviewPage\\lakeOverview.tsx";import React, { useEffect, useMemo, useState } from "react";
import { WidgetStatic } from "@opendash/plugin-monitoring";

import Parse from "parse";

import {
  Avatar,
  Button,
  Flex,
  Input,
  List,
  Row,
  Typography,
  AutoComplete,
} from "antd";
import { useNavigate } from "@opendash/router";
import { SearchOutlined } from "@ant-design/icons";

const { Search } = Input;

const { Title, Text } = Typography;

const LakeOverview = () => {
  // const [lakeId, setLakeId] = useUrlParam(
  //   "lakeid",
  //   null as string | null,
  //   "string"
  // );
  const navigate = useNavigate();

  const [zones, setZones] = useState(
    [] 




  );

  const [searchQuery, setSearchQuery] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const zones = await new Parse.Query("MIAAS_Geographies").find();
    setZones(
      zones.map((zone) => {
        return {
          label: zone.get("label"),
          id: zone.id,
          type: zone.get("description"),
          sensors: zone.get("sensors"),
        };
      })
    );
  };

  //Query Lake Meta Data
  // const lakeQuery = useMemo(() => {
  //   return new Parse.Query("AD4GD_LakeMetaData");
  // }, []);

  // const { result: lakes, reload, error, loading } = useParseQuery(lakeQuery);

  // console.log({ lakes });

  const handleNavigateToStats = (item



) => {
    console.log(item);
    navigate(`/lake/${item.id}`, { state: { item } });
  };

  const handleMapClick = async (id) => {
    try {
      const lakeQuery = new Parse.Query("MIAAS_Geographies").equalTo(
        "objectId",
        id
      );

      const results = await lakeQuery.find();

      if (results.length > 0) {
        const lake = results[0];

        console.log({
          type: lake.get("description"),
          label: lake.get("label"),
          sensors: lake.get("sensors"),
          id: id,
        });

        return {
          type: lake.get("description"),
          label: lake.get("label"),
          sensors: lake.get("sensors"),
          id: id,
        };
      } else {
        console.log("No lake found with the given id");
        return null;
      }
    } catch (error) {
      console.error("Error querying the lake:", error);
      return null;
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
    const filteredOptions = zones
      .filter(
        (zone) =>
          zone.type === "lake" &&
          zone.label.toLowerCase().includes(value.toLowerCase())
      )
      .map((zone) => ({ value: zone.label }));

    setOptions(filteredOptions);
  };

  const handleSelect = (value) => {
    setSearchQuery(value);
  };

  const filteredZones = useMemo(() => {
    const selectedZone = zones.find(
      (zone) =>
        zone.type === "lake" &&
        zone.label.toLowerCase() === searchQuery.toLowerCase()
    );

    if (selectedZone) {
      return [selectedZone];
    }

    return zones.filter(
      (zone) =>
        zone.type === "lake" &&
        zone.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [zones, searchQuery]);

  const mapConfig = useMemo(() => {
    const config = {
      markers: [],
      zones: {
        type: "zones",
        districtsFromZones: zones.map((zone) => zone.id),
        districts: null,
        districtFromDimension: null,
      },
      _history: {
        aggregation: false,
      },
      onEvent: async (type, event) => {
        const objectId = event.features.filter(
          (f) => f.properties.description === "lake"
        )[0].properties.objectId;

        try {
          // Await the result from handleMapClick
          const lakeDetails = await handleMapClick(objectId);

          if (lakeDetails) {
            // Pass the lake details directly to handleNavigateToStats
            handleNavigateToStats(lakeDetails);
          } else {
            console.log("No lake details found.");
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      },
    };

    return config;
  }, [zones]);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Row, { style: { width: "100%", height: "80px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 186}}
        , React.createElement(WidgetStatic, {
          style: { width: "100%", height: "100%" },
          type: "header-widget",
          config: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 187}}
)
      )

      , React.createElement(Row, {
        style: {
          width: "100%",
          height: "calc(90vh - 80px)",
          backgroundColor: "white",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 194}}

        , React.createElement(Flex, {
          gap: "middle",
          align: "start",
          style: { width: "100%", height: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 201}}

          , React.createElement(Flex, {
            gap: "small",
            vertical: true,
            style: { width: "67%", height: "100%", padding: "0 1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 206}}

            , React.createElement(Title, {
              level: 1,
              style: {
                fontWeight: "bold",
                marginBottom: "1%",
                width: "100%",
                letterSpacing: "0.25rem",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 211}}
, "Kleine Seen in Berlin"

            )

            , React.createElement(Input.Group, { compact: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 223}}
              , React.createElement(AutoComplete, {
                options: options,
                style: { width: 220 },
                onSearch: handleSearch,
                onSelect: handleSelect,
                placeholder: "See suchen..." ,
                value: searchQuery, __self: this, __source: {fileName: _jsxFileName, lineNumber: 224}}
              )
              , React.createElement(Button, {
                icon: React.createElement(SearchOutlined, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 233}} ),
                onClick: () => handleSearch(searchQuery), __self: this, __source: {fileName: _jsxFileName, lineNumber: 232}}
              )
            )

            , React.createElement(WidgetStatic, {
              style: { width: "100%", height: "100%" },
              type: "kpi-map",
              config: mapConfig, __self: this, __source: {fileName: _jsxFileName, lineNumber: 238}}
)
          )

          , React.createElement('div', {
            style: {
              width: "33%",
              height: "100%",
              backgroundColor: "#E9ECEF",
              padding: "0 1rem",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 245}}

            , React.createElement(Row, { style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 253}}
              , React.createElement(Title, {
                level: 1,
                style: {
                  fontWeight: "bold",
                  marginBottom: "2rem",
                  fontFamily: "Josefin Sans",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 254}}
, "Wasserqualitätindex"

              )
            )

            , React.createElement(Row, {
              style: {
                marginBottom: "2rem",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 266}}

              , React.createElement(Text, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 271}}, "Der Wasserqualitätsindex vergleicht die unten dargestellten Seen in Bezug auf ihre Wasserqualität basierend auf langfristigen Datenerhebungen."



              )
            )

            , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 278}}
              , React.createElement(Button, {
                type: "link",
                style: {
                  color: "#42A456",
                  textDecoration: "underline",
                  marginBottom: "3rem",
                  padding: 0,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 279}}
, "Mehr zur Datenerhebung"

              )
            )

            , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 292}}
              , React.createElement(List, {
                style: { width: "100%", height: "100%" },
                itemLayout: "horizontal",
                dataSource: filteredZones,
                size: "small",
                split: false,
                renderItem: (item, index) => (
                  React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 300}}
                    , React.createElement(List.Item.Meta, {
                      avatar: 
                        React.createElement(Avatar, {
                          src: 
                            React.createElement('svg', { width: "12", height: "12", __self: this, __source: {fileName: _jsxFileName, lineNumber: 305}}
                              , React.createElement('circle', { cx: "6", cy: "6", r: "6", fill: "#55b169", __self: this, __source: {fileName: _jsxFileName, lineNumber: 306}} )
                            )
                          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 303}}
                        )
                      ,
                      title: 
                        React.createElement('a', {
                          href: "#",
                          onClick: (e) => {
                            e.preventDefault();
                            handleNavigateToStats(item);
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 312}}

                          , item.label
                        )
                      ,
                      // description={item.id}
                      description: "See", __self: this, __source: {fileName: _jsxFileName, lineNumber: 301}}
                    )
                  )
                ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 293}}
              )
            )
          )
        )
      )
    )
  );
};

export default LakeOverview;
