const _jsxFileName = "C:\\Users\\phung\\Downloads\\ad4gd-open-dash\\src\\js\\components\\lakeStats\\lakeStats.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import { Col, Row, Typography } from "antd";
import { WidgetStatic } from "@opendash/plugin-monitoring";
import React, { useMemo } from "react";
import { useLocation, useParams } from "@opendash/router";
import { Carousel } from "../carousel";
import { StarFilled } from "@ant-design/icons";












const IconLabelComponent = ({
  icon: Icon,
  label,
}) => {
  return (
    React.createElement('div', { style: { display: "flex", alignItems: "center", marginTop: "4%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
      , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
        , React.createElement(Icon, {
          style: {
            fontSize: "20px",
            padding: "6px",
            backgroundColor: "#56ECAD",
            borderRadius: "50%",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
        )
      )
      , React.createElement(Text, { style: { fontSize: "16px", fontWeight: "600", marginLeft: "5%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        , label
      )
    )
  );
};

const { Title, Text } = Typography;

const PropertyRow = ({ label, value }) => (
  React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
    , React.createElement(Text, { style: { flex: 0.35, fontSize: "15px", fontWeight: "600" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      , label, ":"
    )
    , React.createElement(Text, { style: { flex: 0.65, fontSize: "15px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, value)
  )
);

const LakeStats = ({}) => {
  const { lakeId } = useParams();
  const location = useLocation();
  const {
    item: { sensors },
  } = location.state || {};

  const t = useTranslation();

  const config = useMemo(() => {
    return {
      _sources: [],
      _items: [],
      _dimensions: [...sensors],
      _history: {},
    };
  }, [sensors]);

  const properties = [
    {
      id: "N9vhwQrU8x",
      name: "Plötzensee",
      area: "76800 m2",
      swimmingUsage: "Ja",
      district: "Mitte",
      circumference: "1645,102 m",
      images: ["1", "2", "3"],
    },
    {
      id: "H7wRivfzrC",
      name: "Flughafensee",
      area: "30.6 ha",
      swimmingUsage: "tbd",
      district: "Reinickendorf",
      circumference: "3.545 km",
      images: ["1", "2", "3"],
    },
    {
      id: "DNuO9mBwVq",
      name: "Buckower Dorfteich",
      area: "tbd",
      swimmingUsage: "tbd",
      district: "Neukölln",
      circumference: "tbd",
      images: ["1", "2", "3"],
    },
    {
      id: "eiqVOoiri9",
      name: "Britzer Kirchteich",
      area: "tbd",
      swimmingUsage: "tbd",
      district: "Neukölln",
      circumference: "tbd",
      images: ["1", "2", "3"],
    },
  ];

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Row, { style: { width: "100%", height: "80px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
        , React.createElement(WidgetStatic, {
          style: { width: "100%", height: "100%" },
          type: "header-widget",
          config: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
)
      )
      , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}
        , React.createElement('div', {
          style: {
            width: "30%",
            backgroundColor: "white",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}

          , React.createElement(Title, { level: 1, style: { fontWeight: "bold", marginBottom: "2%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}}
            , _optionalChain([properties, 'access', _ => _.find, 'call', _2 => _2((item) => item.id === lakeId), 'optionalAccess', _3 => _3.name])
          )
          , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
            , React.createElement(Col, {
              style: {
                alignSelf: "center",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}

              , React.createElement('svg', { width: "12", height: "12", __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
                , React.createElement('circle', { cx: "6", cy: "6", r: "6", fill: "#55b169", __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}} )
              )
              , React.createElement(Text, {
                strong: true,
                style: {
                  lineHeight: 0,
                  fontSize: "15px",
                  marginLeft: "5px",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 139}}

                , _optionalChain([properties, 'access', _4 => _4.find, 'call', _5 => _5((item) => item.id === lakeId), 'optionalAccess', _6 => _6.name]), " I ggf. Zusatzinfos wie Zahl?"

              )
            )
          )

          , React.createElement(Carousel, {
            images: _optionalChain([properties, 'access', _7 => _7.find, 'call', _8 => _8((item) => item.id === lakeId), 'optionalAccess', _9 => _9.images]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}
          )
          , React.createElement(Title, { level: 5, style: { fontWeight: "bold" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 156}}, "Bildbeschreibung zum obenstehenden Bild"

          )

          , React.createElement('div', { style: { marginTop: "6%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}
            , React.createElement(PropertyRow, {
              label: t("Name"),
              value: _optionalChain([properties, 'access', _10 => _10.find, 'call', _11 => _11((item) => item.id === lakeId), 'optionalAccess', _12 => _12.name]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 161}}
            )
            , React.createElement(PropertyRow, {
              label: t("Fläche"),
              value: _optionalChain([properties, 'access', _13 => _13.find, 'call', _14 => _14((item) => item.id === lakeId), 'optionalAccess', _15 => _15.area]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}}
            )
            , React.createElement(PropertyRow, {
              label: t("Badenutzung"),
              value: 
                _optionalChain([properties, 'access', _16 => _16.find, 'call', _17 => _17((item) => item.id === lakeId), 'optionalAccess', _18 => _18.swimmingUsage])
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 169}}
            )
            , React.createElement(PropertyRow, {
              label: t("Bezirk"),
              value: _optionalChain([properties, 'access', _19 => _19.find, 'call', _20 => _20((item) => item.id === lakeId), 'optionalAccess', _21 => _21.district]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}}
            )
            , React.createElement(PropertyRow, {
              label: t("Umfang"),
              value: 
                _optionalChain([properties, 'access', _22 => _22.find, 'call', _23 => _23((item) => item.id === lakeId), 'optionalAccess', _24 => _24.circumference])
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 179}}
            )
          )

          , React.createElement(IconLabelComponent, {
            icon: StarFilled,
            label: "Als Favorit hinzufügen"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 187}}
          )
        )

        , React.createElement('div', {
          style: {
            width: "64%",
            marginLeft: "2%",
            marginTop: "2%",
            marginRight: "2%",
            paddingLeft: "1%",
            paddingRight: "1%",
            paddingBottom: "2%",
            borderRadius: "20px",
            backgroundColor: "white",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 193}}

          , React.createElement(WidgetStatic, { type: "lakeStats-widget", config: config, __self: this, __source: {fileName: _jsxFileName, lineNumber: 206}})
        )

        /* <div
          style={{
            width: "64%",
            marginLeft: "2%",
            marginTop: "2%",
            marginRight: "2%",
            paddingLeft: "1%",
            paddingRight: "1%",
            borderRadius: "20px",
            height: "calc(30vh)",
            backgroundColor: "white",
          }}
        ></div> */
      )
    )
  );
};

export default LakeStats;
